import React, { useContext, useEffect, useState } from 'react';
import { Section } from '../../components/Core';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';
import ModalError from '../../components/ModalError/ModalError';
import Seo from '../../components/Seo/Seo';
import OrderJourneySteps from '../../sections/OrderJourneySteps';
import { CheckoutStepOne } from '../../sections/CheckoutSteps/StepOne';
import { CheckoutStepTwo } from '../../sections/CheckoutSteps/StepTwo';
import { CheckoutStepThree } from '../../sections/CheckoutSteps/StepThree';
import { CheckoutStepFour } from '../../sections/CheckoutSteps/StepFour';
import { CheckoutStepFive } from '../../sections/CheckoutSteps/StepFive';
import { navigate } from 'gatsby';
import API from '../../api/api';

const CheckoutLanding = (props) => {
    const gContext = useContext(GlobalContext);
    const params = new URLSearchParams(props.location.search);
    const leadId = params.get('lead');

    const checkoutStep = gContext?.goGetRegistrationData()?.checkoutStep || 1;

    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        const step = params.get('step');
        gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            checkoutStep: step ? parseInt(step) : 1,
        });
    }, [props.location.search]);

    const stepRouting = () => {
        if (gContext.goGetRegistrationData()) {
            switch (checkoutStep) {
                case 1:
                    return <CheckoutStepOne location={'index'}/>;
                case 2:
                    return <CheckoutStepTwo />;
                case 3:
                    return <CheckoutStepThree />;
                case 4:
                    return <CheckoutStepFour />;
                case 5:
                    return <CheckoutStepFive />;
                default:
                    <span>Something went wrong, no checkout step specified.</span>;
            }
        } else {
            return <></>;
        }
    };

    useEffect(() => {
        API.get(`/CrmModule/v1.0/db/Lead/${leadId}?entities=["Contact", "Address"]&withLinks=true`)
            .then(
                (resp) => {
                    const addressObj = resp.data.data.Address.dbRecords[0];
                    const contactObj = resp.data.data.Contact.dbRecords[0];
                    const leadId = resp.data.data.id;

                    gContext.goSetRegistrationData({
                        ...gContext.goGetRegistrationData(),
                        addressId: addressObj.id,
                        contactId: contactObj.id,
                        searchPremiseResults: {
                            title: addressObj.title
                        },
                        leadId
                    });

                    API.get(`/CrmModule/v1.0/db/Contact/${contactObj.id}`).then(
                        (resp1) => {
                            const contactResp = resp1.data.data.properties;

                            gContext.goSetRegistrationData({
                                ...gContext.goGetRegistrationData(),
                                personalData: {
                                    firstName: contactResp.FirstName,
                                    lastName: contactResp.LastName,
                                    phoneNumber: contactResp.Phone,
                                    email: contactResp.EmailAddress,
                                    companyName: contactResp.CompanyName,
                                    companyPosition: contactResp.CompanyPosition,
                                }
                            });
                        }
                    )
                        .catch(error => {
                            gContext.setAPIErrorMsg({
                                title: 'Error',
                                message: error.response ? error.response?.data.message : error
                            })
                            gContext.setErrorModalVisible(true)
                            navigate('/')
                        })
                }
            )
            .catch(error => {
                gContext.setAPIErrorMsg({
                    title: 'Error',
                    message: error.response ? error.response?.data.message : error
                })
                navigate('/')
            }
            )
    }, []);

    return (
        <>
            <Seo page="checkout-landing" featuredImageUrl={undefined} />
            <Section>
                <Container className="checkoutOrder">
                    {/* Order Journey Step Indicator */}
                    <Row className="justify-content-center">
                        <Col sm={12} md={8} className="text-center mb-1 mb-md-3 mt-1">
                            {gContext.goGetRegistrationData() && <OrderJourneySteps currentStep={checkoutStep} />}
                        </Col>
                    </Row>

                    {/* Step Router */}
                    {stepRouting()}
                </Container>
            </Section>
        </>
    );
};

export default CheckoutLanding
